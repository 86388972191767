require('sanitize.css')
require('sanitize.css/typography.css')
require('sanitize.css/forms.css')

// Typography
require('./src/styles/typography.scss')

// Global Styles
require('./src/styles/global.scss')

// Code Highlighting
require('./src/styles/code.scss')

// Custom SCSS
try {
	require('./src/styles/custom.scss');
} catch (e){
	console.log("No Custom Styles Detected")
}